/* eslint-disable react/no-unescaped-entities */
/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import styled from "@emotion/styled";
import Div100vh from "react-div-100vh";

// Images
import css from "@emotion/css";
import { keyframes } from "@emotion/core";
// import ImgBackgroundIntro from "../Images/Journal/ImgBackgroundIntro";
import JournalHandwritting from "../Animations/JournalHandwritting";
import TextAssistant24 from "../Base/text/TextAssistant24";
import VideoPopup from "../VideoPlayers/VideoPopup";
import NextPageArrow from "../Navigation/NextPageArrow";

// Video
import IntroVideo from "../../videos/anana-vestige-journal-video.mp4";
import VideoBackground from "../../videos/video-journal-intro.mp4";
import VideoJournal from "../../videos/video-journal.png";

// Images
import VideoCover from "../../images/journal/anana-vestige-journal-video-cover.png";
import VideoPLayerFullScreen from "../VideoPlayers/VideoPlayerFullScreen";
import { colors, fonts, mediaQueries } from "../../configs/_configSite";
// import ImgBackgroundIntro from "../Images/Journal/ImgBackgroundIntro";
import FireFly from "../Animations/FireFly";
import ImgProductJournal1 from "../Images/Journal/products/ImgProductJournal1";

const ContainerMain = styled(Div100vh)`
  position: relative;
  height: 100vh;
  width: 100vw;
  background-image: url(${VideoJournal});
  background-size: cover;
  background-repeat: no-repeat;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const ContainerIntroText = styled.div`
  position: absolute;
  display: inline-table;
  box-sizing: border-box;
  z-index: 2;
  opacity: 0;
  width: 700px;
  right: 15vw;
  margin: auto;
  top: 50vh;
  bottom: 0;
  animation: ${fadeIn} 0.5s ease-in 4s forwards;

  @media (max-width: ${mediaQueries.lg}px) {
    width: 500px;
    right: 10vw;
  }

  @media (max-width: ${mediaQueries.md}px) {
    max-width: 100%;
    width: 100%;
    right: initial;
    padding: 15px 30px;
  }
  @media (max-width: ${mediaQueries.sm}px) {
    margin-top: -100px;
  }
  @media (max-width: ${mediaQueries.xs}px) {
    margin-top: -200px;
  }
`;

const DonateLinkStyled = styled.a`
  font-family: ${fonts.primary};
  text-decoration: none;
  font-size: 2rem;
  background-color: ${colors.gold};
  color: #fff;
  margin-top: 15px;
  padding: 0.5rem 1rem;
  margin-left: auto;
  float: right;
  display: block;
  transition: all 0.2s;
  &:hover {
    background-color: #fff;
    color: ${colors.gold};
  }
  @media (max-width: ${mediaQueries.xs}px) {
    float: none;
    margin: 0 auto;
    width: max-content;
    font-size: 12px;
  }
`;

export default () => {
  return (
    <ContainerMain>
      <JournalHandwritting />
      <ContainerIntroText>
        <TextAssistant24
          css={css`
            font-size: 16px !important;
          `}
        >
          We get it... These days, life marvels us with its power, beauty, and
          opulent grace expressed during tremendous tumultuous, and sometimes
          splendid situations. In the mist of life’s dramatic, theatrical
          moments, take this incredible opportunity to capture your thoughts,
          feelings, and learning ventures to write down pertinent temporaneous
          vestiges that linger in the aftermath of your circumstances. Listen to
          Pastor Ted's sermon on Prayer!
        </TextAssistant24>
        <DonateLinkStyled
          href="https://www.reliancechurch.org/sermons/?sapurl=Lys3YmY4L2xiL21pLytueThzMnJtP2F1dG9wbGF5PXRydWUmYnJhbmRpbmc9dHJ1ZSZlbWJlZD10cnVlJnJlY2VudFJvdXRlPWFwcC53ZWItYXBwLmxpYnJhcnkubGlzdCZyZWNlbnRSb3V0ZVNsdWc9JTJCbTY2N3NmcA=="
          target="_blank"
          css={css`
            @media (max-width: ${mediaQueries.md}px) {
              margin: 0 auto;
              float: initial;
              display: inherit;
            }
          `}
        >
          Watch Now
        </DonateLinkStyled>
        <div
          css={css`
            position: relative;
            width: 400px;
            height: 250px;

            @media (max-width: ${mediaQueries.lg}px) {
              width: 300px;
              height: 200px;
            }

            @media (max-width: ${mediaQueries.md}px) {
              width: 300px;
              height: 200px;
              margin: 15px auto 0 auto;
            }

            @media (max-width: ${mediaQueries.sm}px) {
              max-width: 100%;
              width: 100%;
              height: 400px;
            }
            @media (max-width: ${mediaQueries.xs}px) {
              height: auto;
              padding: 0 15px 30px;
            }
          `}
        >
          <ImgProductJournal1 />
        </div>
      </ContainerIntroText>
      <VideoPopup url={IntroVideo} image={VideoCover} />
      <NextPageArrow text="Product" />
      <VideoPLayerFullScreen video={VideoBackground} />
      <FireFly />
      {/* <ImgBackgroundIntro /> */}
    </ContainerMain>
  );
};
